import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';
import { actionType } from '../../actions/log-email-history';

const initState = {
  ...baseInitState,
};

export default function reducer(state = initState, action: any) {
  switch (action.type) {
    default:
      return baseReducer(state, action, actionType);
  }
}
