import { Box } from '@mui/system';
import { useEffect } from 'react';
import DataTable from '../../../components/table/DataTable';
import logEmailHistory from '../../../redux/actions/log-email-history';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { detaiLogEmailColumns } from '../models/columns';

const LogEmailHistory = ({ logEmailId }: any) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.logEmailHistory);

  useEffect(() => {
    if (logEmailId) {
      dispatch(logEmailHistory.fetchHistory({ ...data.params, id: logEmailId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logEmailId]);

  return (
    <Box>
      <Box component="h2" sx={{ fontSize: [22, 28, 32] }}>
        Detail Email Log
      </Box>
      <DataTable
        columns={detaiLogEmailColumns()}
        data={data}
        showTotal
        height="75vh"
        fetchList={logEmailHistory.fetchHistory}
      />
    </Box>
  );
};

export default LogEmailHistory;
