import { BorderColor, Delete, Visibility } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { formatMoney } from '../../../utils/string';
import { formatSla } from '../../../utils/time';

export const providerColumns = (
  role: any,
  handleEdit: (data: any) => void,
  handleDetail: (data: any) => void,
  handleDelete: (data: any) => void,
): GridColDef[] => {
  return [
    { field: 'company_name', headerName: 'Company', flex: 1, minWidth: 150 },
    { field: 'provider_name', headerName: 'Provider', flex: 1, minWidth: 150 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      renderCell: (data) => (data.row.status ? 'Active' : 'Non Active'),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      minWidth: 150,
      renderCell: (data) => (
        <Grid container alignItems={'center'}>
          {role.view && (
            <Tooltip title="Detail" sx={{ mr: 1 }} onClick={() => handleDetail(data.row)}>
              <Visibility fontSize="small" className="pointer" color="primary" />
            </Tooltip>
          )}
          {role.edit && (
            <Tooltip title="Edit" sx={{ mr: 1 }} onClick={() => handleEdit(data.row)}>
              <BorderColor fontSize="small" className="pointer" color="info" />
            </Tooltip>
          )}
          {role.delete && (
            <Tooltip title="Delete" onClick={() => handleDelete(data.row)}>
              <Delete fontSize="small" className="pointer" color="error" />
            </Tooltip>
          )}
        </Grid>
      ),
    },
  ];
};

export const channelColumns = (
  role: any,
  handleEdit: (data: any) => void,
  handleDelete: (data: any) => void,
): GridColDef[] => {
  return [
    { field: 'channel_name', headerName: 'Payment Channel', flex: 1, minWidth: 150 },
    { field: 'code', headerName: 'Channel Code', flex: 1, minWidth: 250 },
    {
      field: 'fee_fix_value',
      headerName: 'Cash In/Out Fee (Fix)',
      flex: 1,
      minWidth: 130,
      renderCell: ({ value }) => formatMoney(value),
    },
    {
      field: 'fee_percentage',
      headerName: 'Cash In/Out Fee (%)',
      flex: 1,
      minWidth: 160,
      renderCell: ({ value }) => value + '%',
    },
    {
      field: 'max_transaction',
      headerName: 'Max Transaction',
      flex: 1,
      minWidth: 140,
      renderCell: ({ value }) => formatMoney(value),
    },
    {
      field: 'expired_time',
      headerName: 'Expired Time',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => formatSla(value),
    },
    {
      field: 'cash_in',
      headerName: 'Cash In',
      flex: 1,
      minWidth: 100,
      renderCell: ({ value }) => (value ? 'Yes' : '-'),
    },
    {
      field: 'cash_out',
      headerName: 'Cash Out',
      flex: 1,
      minWidth: 100,
      renderCell: ({ value }) => (value ? 'Yes' : '-'),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: ({ value }) => (value ? 'Active' : 'Non Active'),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (data) => (
        <Grid container alignItems={'center'}>
          {role.edit && (
            <Tooltip title="Edit" sx={{ mr: 1 }} onClick={() => handleEdit(data.row)}>
              <BorderColor fontSize="small" className="pointer" color="info" />
            </Tooltip>
          )}
          {role.delete && (
            <Tooltip title="Delete" onClick={() => handleDelete(data.row)}>
              <Delete fontSize="small" className="pointer" color="error" />
            </Tooltip>
          )}
        </Grid>
      ),
    },
  ];
};
