import httpService from '../../../adapters/httpService';
import { URL_API } from '../../../configs';
import baseActions from '../baseActions';

const path = 'cashout';

export const actionType = (action: string) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const http = httpService();
const index = {
  ...baseActions(path, URL_API, actionType),
  fetchDetail: (params: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('fetchDetail').request });
      return http.get(`${URL_API}/cashout/detail`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetchDetail').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchDetail').error });
          throw error.response;
        },
      );
    };
  },
  fetchDetailById: (id: string) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('fetchDetail').request });
      return http.get(`${URL_API}/cashout/detail/id/${id}`).then(
        (response) => {
          dispatch({ type: actionType('fetchDetail').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchDetail').error });
          throw error.response;
        },
      );
    };
  },
  fetchItems: (params: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('fetchItems').request });
      return http.get(`${URL_API}/cashout/${params.id}/items`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetchItems').success, response: response.data, params });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchItems').error });
          throw error.response;
        },
      );
    };
  },
  getList: (params?: any) => {
    return http.get(`${URL_API}/${path}`, { params }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error.response;
      },
    );
  },
  generateQRCode: () => {
    return (dispatch: any) => {
      dispatch({ type: actionType('generateQRCode').request });
      return http.get(`${URL_API}/cashout/generate`).then(
        (response) => {
          dispatch({ type: actionType('generateQRCode').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('generateQRCode').error });
          throw error.response;
        },
      );
    };
  },
  approve: (data: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('update').request });
      return http.post(`${URL_API}/cashout/approval`, data).then(
        (response) => {
          dispatch({ type: actionType('update').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('update').error });
          throw error.response;
        },
      );
    };
  },
  reject: (data: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('update').request });
      return http.post(`${URL_API}/cashout/rejected`, data).then(
        (response) => {
          dispatch({ type: actionType('update').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('update').error });
          throw error.response;
        },
      );
    };
  },
  disburseFee: (data: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('update').request });
      return http.post(`${URL_API}/cashout/fee-payment`, data).then(
        (response) => {
          dispatch({ type: actionType('update').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('update').error });
          throw error.response;
        },
      );
    };
  },
  fetchListInvoice: (params: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('fetch-invoice').request });
      return http.get(`${URL_API}/cashout/detail/outstanding`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetch-invoice').success, response: response.data, params });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetch-invoice').error });
          throw error.response;
        },
      );
    };
  },
  addInvoice: (data: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('add-invoice').request });
      return http.post(`${URL_API}/cashout/detail/bulk`, data).then(
        (response) => {
          dispatch({ type: actionType('add-invoice').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('add-invoice').error });
          throw error.response;
        },
      );
    };
  },
  deleteInvoice: (data: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('delete-invoice').request });
      return http.delete(`${URL_API}/cashout/detail/bulk`, { data }).then(
        (response) => {
          dispatch({ type: actionType('delete-invoice').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('delete-invoice').error });
          throw error.response;
        },
      );
    };
  },
  export: (params: any) => {
    const http = httpService();
    return http.get(`${URL_API}/cashout/export`, { params, responseType: 'arraybuffer' }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error.response;
      },
    );
  },
  resendEmail: (id: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('update').request });
      return http.post(`${URL_API}/cashout/${id}/resend-email`, id).then(
        (response) => {
          dispatch({ type: actionType('update').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('update').error });
          throw error.response;
        },
      );
    };
  },
  doneManual: (id: any, reason: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('update').request });
      return http.put(`${URL_API}/${path}/${id}/done`, { reason }).then(
        (response) => {
          dispatch({ type: actionType('update').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('update').error });
          throw error.response;
        },
      );
    };
  },
  resetParamsDataItems: () => {
    return (dispatch: any) => dispatch({ type: actionType('resetParamsDataItems').success });
  },
  resetParamsInvoice: () => {
    return (dispatch: any) => dispatch({ type: actionType('resetParamsInvoice').success });
  },
};

export default index;
