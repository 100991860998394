import { useEffect, useState } from 'react';
import providerChannel from '../../../redux/actions/company-provider-channel';
import { useAppDispatch } from '../../../redux/store';

export const useFormChildViewModel = (props: any) => {
  const { providerId, companyId, fields, setValue, nestedIndex } = props;
  const [providerChannelList, setProviderChannelList] = useState<any[]>([]);
  const [selectedProviderChannel, setSelectedProviderChannel] = useState<any[]>([]);
  const dispatch = useAppDispatch();

  const handleFetchProviderChannel = async () => {
    try {
      const params = {
        payment_provider_id: providerId,
        company_id: companyId,
        provider_channel_status: true,
        capability: 1,
        sort: 'desc',
      };

      const res = await dispatch(providerChannel.fetchAll(params));
      const temp = res.data || [];
      setProviderChannelList(
        temp.map((val: any) => ({
          label: `${val.channel_name} (${val.code})`,
          value: val.id,
          disabled: false,
          fee_fix_value: val.fee_fix_value,
          fee_percentage: val.fee_percentage,
        })),
      );
      if (fields.length > 0)
        setSelectedProviderChannel(
          temp.map((_: any, i: number) => (fields[i] ? fields[i].provider_channel_id : '')),
        );
      else setSelectedProviderChannel(temp.map(() => ''));
    } catch (error) {}
  };

  const handleChangeProviderChannel = (id: any, index: number) => {
    const temp = [...selectedProviderChannel];
    temp[index] = id;

    const tempData = providerChannelList.find((val: any) => String(val.value) === String(id));
    if (tempData) {
      setValue(
        `provider[${nestedIndex}].provider_channel[${index}].cash_in_fix`,
        String(tempData.fee_fix_value),
      );
      setValue(
        `provider[${nestedIndex}].provider_channel[${index}].cash_in_percentage`,
        String(tempData.fee_percentage),
      );
    }

    setSelectedProviderChannel(temp);
  };

  useEffect(() => {
    if (providerId && companyId) handleFetchProviderChannel();
  }, [providerId, companyId]);

  const validateFieldArray = (errors: any, indexParent: number, index: number, name: string) => {
    if (
      errors.provider &&
      errors.provider[indexParent] &&
      errors.provider[indexParent].provider_channel &&
      errors.provider[indexParent].provider_channel[index] &&
      errors.provider[indexParent].provider_channel[index][name]
    ) {
      return errors.provider[indexParent].provider_channel[index][name].message;
    }

    return '';
  };

  const getProviderChannelList = () =>
    providerChannelList.map((val) => ({
      ...val,
      disabled: selectedProviderChannel.includes(val.value),
    }));

  return {
    providerChannelList: getProviderChannelList(),
    handleChangeProviderChannel,
    validateFieldArray,
  };
};
