import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import auth from '../reducers/auth';
import company from '../reducers/company';
import partner from '../reducers/partner';
import product from '../reducers/product';
import channel from '../reducers/channel';
import provider from '../reducers/provider';
import providerChannel from '../reducers/provider-channel';
import companyProvider from '../reducers/company-provider';
import companyProviderChannel from '../reducers/company-provider-channel';
import cashIn from '../reducers/cash-in';
import cashInHistory from '../reducers/cash-in-history';
import cashOut from '../reducers/cash-out';
import cashOutHistory from '../reducers/cash-out-history';
import user from '../reducers/user';
import role from '../reducers/role';
import tag from '../reducers/tag';
import emailTemplate from '../reducers/email-template';
import logEmail from '../reducers/log-email';
import logEmailHistory from '../reducers/log-email-history';

//List Reducers
const reducer = combineReducers({
  auth,
  company,
  partner,
  product,
  channel,
  provider,
  providerChannel,
  companyProvider,
  companyProviderChannel,
  cashIn,
  cashInHistory,
  cashOut,
  cashOutHistory,
  user,
  role,
  tag,
  emailTemplate,
  logEmail,
  logEmailHistory,
});
export const store = configureStore({
  reducer: reducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
