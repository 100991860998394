import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { alertError, alertSuccess } from '../../utils/alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { Save } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { IRevisiForm, revisiDefaultValues, revisiResolver } from './models/form';
import cashOut from '../../redux/actions/cash-out';
import { DialogContentText } from '@mui/material';

const RevisiDialog = ({ open, handleClose, data }: any) => {
  const methods = useForm<IRevisiForm>({ resolver: revisiResolver });
  const loading = useAppSelector((state) => state.cashOut.isLoadingSubmit);
  const params = useAppSelector((state) => state.cashOut.params);
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    const formData = {
      provider_id: data.provider_id,
      channel_id: data.channel_id,
    };
    try {
      await dispatch(cashOut.updateData(data?.id, formData));
      alertSuccess(`Revisi berhasil`).then(() => {
        handleClose();
        dispatch(cashOut.fetchList(params));
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };
  React.useEffect(() => {
    if (data?.id && open === true) {
      methods.reset(data);
    } else {
      methods.reset(revisiDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">Confirm Revise</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to revise this cashout transaction?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Yes, Revisi
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default RevisiDialog;
