import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ModalDelete from '../../../components/modal/ModalDelete';
import DataTable from '../../../components/table/DataTable';
import emailTemplate from '../../../redux/actions/email-template';
import EmailTemplateFormDialog from './EmailTemplateFormDialog';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { alertError } from '../../../utils/alert';
import { emailTemplateColumns } from '../models/columns';
import { Button, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';

const EmailTemplateIndex = ({ companyProvider, role }: any) => {
  const [dataSelected, setDataSelected] = useState({});
  const [openEmailTemplateForm, setOpenEmailTemplateForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.emailTemplate);

  const handleAdd = async () => {
    try {
      setDataSelected({});
      setOpenEmailTemplateForm(true);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleEdit = async (data: any) => {
    try {
      const res = await emailTemplate.fetchDetail(data.id);

      setDataSelected({ ...data, ...res });
      setOpenEmailTemplateForm(true);
    } catch (error: any) {
      alertError(error.response?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };
  const handleDelete = (data: any) => {
    setDataSelected(data);
    setOpenDelete(true);
  };

  useEffect(() => {
    if (companyProvider) {
      let params = {
        ...data.params,
        company_id: companyProvider.company_id,
        payment_provider_id: companyProvider.payment_provider_id,
      };
      dispatch(emailTemplate.fetchList(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProvider]);

  useEffect(() => {
    dispatch(emailTemplate.fetchList());
  }, [dispatch]);

  return (
    <Box>
      <Box component="h2" sx={{ fontSize: [22, 28, 32] }}>
        Email Template
      </Box>
      <Grid container sx={{ mb: 1 }} justifyContent={'space-between'}>
        {/* {role.create && (
          <Grid item md={3} textAlign={'end'}>
            <Button variant="contained" startIcon={<Add />} onClick={handleAdd}>
              Add Template
            </Button>
          </Grid>
        )} */}
        <Grid item md={3} textAlign={'start'}>
          <Button variant="contained" startIcon={<Add />} onClick={handleAdd}>
            Add Template
          </Button>
        </Grid>
      </Grid>
      <DataTable
        columns={emailTemplateColumns(role, handleEdit, handleDelete)}
        data={data}
        height="60vh"
        showTotal
        loading={data?.isLoading}
        fetchList={emailTemplate.fetchList}
      />
      <EmailTemplateFormDialog
        companyProvider={companyProvider}
        open={openEmailTemplateForm}
        handleClose={() => setOpenEmailTemplateForm(false)}
        data={dataSelected}
      />
      <ModalDelete
        open={openDelete}
        dataSelected={dataSelected}
        handleClose={() => setOpenDelete(false)}
        deleteData={emailTemplate.deleteData}
        fetchList={emailTemplate.fetchList}
        data={data}
      />
    </Box>
  );
};

export default EmailTemplateIndex;
