import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import DataTable from '../../../components/table/DataTable';
import logEmail from '../../../redux/actions/log-email';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import LogEmailFilter from './LogEmailFilter';
import { logEmailColumns } from '../models/columns';
import LogEmailHistory from './LogEmailHistory';
import { alertSuccess, alertError } from '../../../utils/alert';

const LogEmailIndex = ({ role }: any) => {
  const [dataSelected, setDataSelected] = useState<{ id: string } | null>(null);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.logEmail);

  const handleDetail = (data: any) => {
    setDataSelected(data);
  };

  const handleResend = async (id: any) => {
    try {
      await dispatch(logEmail.resendEmail(id));
      alertSuccess('Email berhasil dikirim ulang');
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  useEffect(() => {
    dispatch(logEmail.fetchList(data.params));
    return () => dispatch(logEmail.resetParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box component="h1" sx={{ mt: 0, fontSize: [22, 28, 32] }}>
        Email Log
      </Box>
      <Box style={{ width: '100%' }}>
        <LogEmailFilter />
      </Box>
      <DataTable
        columns={logEmailColumns(role, handleDetail, handleResend)}
        data={data}
        height="75vh"
        showTotal
        fetchList={logEmail.fetchList}
      />
      {dataSelected && <LogEmailHistory logEmailId={dataSelected?.id} role={role} />}
    </Box>
  );
};

export default LogEmailIndex;
