import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, string } from 'yup';

export interface ITransferForm {
  batch_number: string;
  provider_id: string;
  channel_id: number;
  channel_name: string;
  scheduler: boolean;
  scheduler_at: string;
}

export const transferDefaultValues = {
  batch_number: '',
  provider_id: '',
  channel_id: 0,
  channel_name: '',
  scheduler: true,
  scheduler_at: '',
};

export const transferResolver = yupResolver(
  object({
    batch_number: string().required('Required field'),
    provider_id: number()
      .transform((value) => (value === 0 ? undefined : value))
      .required('Required field'),
    scheduler_at: string().when('scheduler', {
      is: true,
      then: string().required('Required field'),
    }),
  }),
);

export interface IRevisiForm extends ITransferForm {}

export const revisiDefaultValues = transferDefaultValues;

export const revisiResolver = transferResolver;

export interface IApproveForm {
  batch_number: string;
  otp_code: string;
}

export const approveDefaultValues = {
  batch_number: '',
  otp_code: '',
};

export const approveResolver = yupResolver(
  object({
    otp_code: string()
      .required('Required field')
      .length(6, 'Verification Code must be exactly 6 characters'),
  }),
);

export interface IRejectForm {
  batch_number: string;
  otp_code: string;
  reason: string;
}

export const rejectDefaultValues = {
  batch_number: '',
  otp_code: '',
  reason: '',
};

export const rejectResolver = yupResolver(
  object({
    otp_code: string()
      .required('Required field')
      .length(6, 'Verification Code must be exactly 6 characters'),
  }),
);

export interface IFeeLaterForm {
  otp_code: string;
}

export const feeLaterDefaultValues = {
  otp_code: '',
};

export const feeLaterResolver = yupResolver(
  object({
    otp_code: string()
      .required('Required field')
      .length(6, 'Verification Code must be exactly 6 characters'),
  }),
);

export interface ICashOutExportForm {
  start_date: any | null;
  end_date: any | null;
}

export const defaultCashOutExportForm = {
  start_date: null,
  end_date: null,
};

export interface IDoneManualForm {
  reason: string;
}

export const doneManualDefaultValues = {
  reason: '',
};

export const doneManualResolver = yupResolver(
  object({
    reason: string().required('Required field').max(100, 'Reason must be less than 100 characters'),
  }),
);
