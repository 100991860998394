import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, string, array } from 'yup';

export interface IEmailTemplateForm {
  company_id: number;
  partner_ids: string[];
  email_cc: string;
  subject: string;
  content: string;
}

export const emailTemplateDefaultValues = {
  company_id: 0,
  partner_ids: [''],
  email_cc: '',
  subject: '',
  content: '',
};

export const emailTemplateResolver = yupResolver(
  object({
    company_id: number().required('Required field').moreThan(0, 'Required field'),
    partner_ids: array()
      .of(
        string().test(
          'is-valid-id',
          'Each partner ID must be a valid string or empty for "All Partner"',
          (value) => {
            return value === '' || (value !== undefined && /^[0-9]+$/.test(value));
          },
        ),
      )
      .required('Partner IDs are required')
      .test('at-least-one-id', 'At least one partner ID is required', (value) => {
        // Ensure there is at least one valid partner ID
        return !!(value && value.length > 0 && value.some((id) => id !== ''));
      }),
    email_cc: string().notRequired(),
    subject: string().required('Required field'),
    content: string()
      .required('Required field')
      .test('is-not-empty-content', 'Required Field', (value) => value !== '<p><br></p>'),
  }),
);
