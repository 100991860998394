import { Box, Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button } from '@mui/material';
import { alertError, alertSuccess } from '../../utils/alert';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { doneManualDefaultValues, IDoneManualForm } from './models/form';
import { FormProvider, useForm } from 'react-hook-form';
import { doneManualResolver } from './models/form';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputField } from '../../components/fields/RenderField';
import cashOut from '../../redux/actions/cash-out';

const DoneManualDialog = ({ open, handleClose, data, handleRefetchList }: any) => {
  const dispatch = useAppDispatch();
  const methods = useForm<IDoneManualForm>({ resolver: doneManualResolver });

  const loadingSubmit = useAppSelector((state) => state.cashOut.isLoadingSubmit);

  const onSubmit = async (value: IDoneManualForm) => {
    try {
      await dispatch(cashOut.doneManual(data.id, value.reason));
      alertSuccess(`Cashout with batch number ${data?.batch_number} has been done manually`);
      handleClose();
      handleRefetchList();
      methods.reset(doneManualDefaultValues);
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          width: '500px',
          height: '400px',
          maxHeight: '80vh',
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
          Done Manual
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormProvider {...methods}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputField name="reason" label="Reason" rows={6} placeholder="Input done manual reason" />
                    </Grid>
                  </Grid>
                </FormProvider>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={handleClose} variant="outlined" type="button">
            Cancel
          </Button>
          <LoadingButton loading={loadingSubmit} type="submit" variant="contained">
          Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DoneManualDialog;
