import * as React from 'react';
import {
  FormHelperText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { InputField, SelectField, SwitchField } from '../../components/fields/RenderField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Delete, Save } from '@mui/icons-material';
import { IProductNewForm, resolver } from './models/formNew';
import { Box } from '@mui/system';
import { Grid, IconButton, InputLabel } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormProviderChannel from './FormProviderChannel';
import { useFormViewModel } from './models/useFormViewModel';
import BasicPopover from '../../components/popover/Popover';

const FormDialogNew = ({ open, handleClose, data }: any) => {
  const methods = useForm<IProductNewForm>({ resolver });
  const {
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = methods;
  const companyId = watch('company_id');
  const { fields, append, remove } = useFieldArray({ control, name: 'provider' });
  const {
    companyList,
    loading,
    onSubmit,
    providerList,
    handleChangeProvider,
    selectedProvider,
    validateFieldArray,
    resetSelectedProvider,
    handleChangeCompany,
    handleDeleteProvider,
  } = useFormViewModel({
    data,
    handleClose,
    open,
    methods,
    fields,
    companyId,
  });

  const applyToAllChannels = (index: number) => {
    const currentValues = getValues();
    console.log('Current :', currentValues);
    const provider = currentValues.provider[index];
    const feeFixValue = provider.fee_fix_value;
    const feePercentage = provider.fee_percentage;

    provider.provider_channel.forEach((channel: any, channelIndex: number) => {
      console.log('Detail :', provider);
      setValue(
        `provider[${index}].provider_channel[${channelIndex}].fee_fix_value` as keyof IProductNewForm,
        feeFixValue,
      );
      setValue(
        `provider[${index}].provider_channel[${channelIndex}].fee_percentage` as keyof IProductNewForm,
        feePercentage,
      );
    });
  };

  const activateAllChannel = (index: number) => {
    const currentValues = getValues();
    const provider = currentValues.provider[index];

    provider.provider_channel.forEach((channel: any, channelIndex: number) => {
      setValue(
        `provider[${index}].provider_channel[${channelIndex}].status` as keyof IProductNewForm,
        1,
      );
    });
  };

  const deactivateAllChannel = (index: number) => {
    const currentValues = getValues();
    const provider = currentValues.provider[index];

    provider.provider_channel.forEach((channel: any, channelIndex: number) => {
      setValue(
        `provider[${index}].provider_channel[${channelIndex}].status` as keyof IProductNewForm,
        0,
      );
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{data.id ? 'Edit' : 'Add'} Product</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <SelectField
                    name="company_id"
                    label="Company Name"
                    options={companyList.map((data: any) => ({ value: data.id, label: data.name }))}
                    customOnChange={(_: any) => handleChangeCompany()}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <InputField
                    name="product_name"
                    label="Product Name"
                    maxLength={100}
                    pattern={/^[\w. ]*$/i}
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputField
                    name="code"
                    label="Product Code"
                    maxLength={6}
                    pattern={/^[\w ]*$/i}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <InputField
                    name="cashout_fee_fix_value"
                    label="Product Fee (Rp)"
                    type="number"
                    min={0}
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputField
                    name="cashout_fee_percentage"
                    label="Product Fee (%)"
                    type="number"
                    max={100}
                  />
                </Grid>
              </Grid>

              <SwitchField name="status" label="Aktif" />
              {fields.map((item: any, index: number) => (
                <Box
                  key={item.id}
                  sx={{ mb: 2, border: 1, borderColor: 'grey.400', borderRadius: 1, padding: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <SelectField
                        name={`provider[${index}].provider_id`}
                        label="Provider"
                        disabled={!!item.disabled}
                        options={providerList}
                        customOnChange={(e: any) => handleChangeProvider(e.target.value, index)}
                        isFieldArray
                        isError={!!validateFieldArray(errors, index)}
                        customError={
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {validateFieldArray(errors, index)}
                          </FormHelperText>
                        }
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <InputField
                        name={`provider[${index}].fee_fix_value`}
                        label="Transaction Fee (Fix)"
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <InputField
                        name={`provider[${index}].fee_percentage`}
                        label="Transaction Fee (%)"
                        type="number"
                        max={100}
                      />
                    </Grid>
                    <Grid item sm={2} textAlign="end">
                      <Grid container spacing={2}>
                        <Grid item xs={8} sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                          <Button
                            variant="text"
                            sx={{
                              textTransform: 'none',
                              color: 'black',
                            }}
                            onClick={() => applyToAllChannels(index)}>
                            Apply to All
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <BasicPopover vertical="center" horizontal="left">
                            <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                              <Button
                                variant="text"
                                sx={{
                                  justifyContent: 'flex-start',
                                  textTransform: 'none',
                                  color: 'black',
                                  mr: 2,
                                }}
                                onClick={() => activateAllChannel(index)}>
                                Activate All Channel Provider
                              </Button>
                              <Button
                                variant="text"
                                sx={{
                                  justifyContent: 'flex-start',
                                  textTransform: 'none',
                                  color: 'black',
                                  mr: 2,
                                }}
                                onClick={() => deactivateAllChannel(index)}>
                                Deactivate All Channel Provider
                              </Button>
                              <Button
                                variant="text"
                                sx={{
                                  justifyContent: 'flex-start',
                                  textTransform: 'none',
                                }}
                                onClick={() => {
                                  remove(index);
                                  handleDeleteProvider(index);
                                }}
                                color="error"
                                startIcon={<Delete />}>
                                Delete Provider
                              </Button>
                            </Box>
                          </BasicPopover>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <FormProviderChannel
                    control={control}
                    nestedIndex={index}
                    providerId={selectedProvider[index]}
                    companyId={companyId}
                    errors={errors}
                    id={data.id}
                    setValue={setValue}
                  />
                </Box>
              ))}

              <Box sx={{ mb: 2 }}>
                <IconButton
                  color="primary"
                  component="label"
                  style={{ padding: 0 }}
                  onClick={() => append({ provider_id: '', provider_channel: [] })}>
                  <AddCircleIcon />
                  <InputLabel style={{ marginLeft: 8 }}>Add Provider</InputLabel>
                </IconButton>
              </Box>
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button
              onClick={() => {
                handleClose();
                resetSelectedProvider();
              }}
              variant="outlined"
              type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default FormDialogNew;
