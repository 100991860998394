import { ExpandMore, Key } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { CheckboxGroupField, CheckboxField } from '../../../../components/fields/RenderField';
import { useFieldArray } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

interface Props {
  data: {
    id: number;
    menu_id: number;
    name: string;
    menu_code: string;
    permission: {
      create: boolean;
      delete: boolean;
      edit: boolean;
      view: boolean;
    };
  };
  index: number;
}

const PermissionMenu: React.FC<Props> = (props) => {
  const { data, index } = props;
  const { setValue } = useFormContext();

  const handlePermissionChange = (permissionType: string, checked: boolean) => {
    setValue(`permissions.${index}.permission.${permissionType}`, checked);
  };

  return (
    <Accordion
      sx={{
        border: (theme) => `1px solid ${theme.palette.action.disabled}`,
        borderRadius: 1,
        '&:before': { display: 'none' },
        color: 'gray',
        mb: 2,
      }}
      defaultExpanded={!data?.id}
      elevation={0}
      disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ minHeight: '24px', px: '14px', '.MuiAccordionSummary-content': { my: '8px' } }}>
        {data.name}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {Object.entries(data.permission).map(([key, value]) => (
            <CheckboxField
              key={key}
              label={key}
              name={`permissions.${index}.permission.${key}`}
              defaultValue={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePermissionChange(key, e.target.checked)
              }
            />
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PermissionMenu;
