import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { InputField, SelectField } from '../../../components/fields/RenderField';
import { alertError, alertSuccess } from '../../../utils/alert';
import { Box, FormHelperText, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  emailTemplateDefaultValues,
  emailTemplateResolver,
  IEmailTemplateForm,
} from '../models/form';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import emailTemplate from '../../../redux/actions/email-template';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { Save } from '@mui/icons-material';
import company from '../../../redux/actions/company';
import TextEditor from '../../../components/text-editor/TextEditor';
import Label from '../../../components/label/Label';

const EmailTemplateFormDialog = ({ open, handleClose, data, provider }: any) => {
  const methods = useForm<IEmailTemplateForm>({ resolver: emailTemplateResolver });
  const { errors } = methods.formState as {
    errors: {
      partner_ids?: { message: string }[] | { message: string };
      content?: { message: string };
    };
  };
  const { setValue } = methods;
  const [partnerIds, setPartnerIds] = useState<string[]>(['']);

  const [content, setContent] = useState(data?.content || '');

  const loading = useAppSelector((state) => state.emailTemplate.isLoadingSubmit);
  const params = useAppSelector((state) => state.emailTemplate.params);
  const companyList = useAppSelector((state) => state.company.dataAll);
  const selectedPartner = useAppSelector((state) => state.emailTemplate.dataAll);

  const partnerList = selectedPartner.map((partner: any) => {
    let updatedPartner = {
      id: partner.partner_id,
      name: partner.partner_name,
      disabled: partner.is_use,
    };

    if (data?.id) {
      data.partners.forEach((selectedPartner: any) => {
        if (selectedPartner.id === partner.partner_id) {
          updatedPartner.disabled = false;
        }
      });
    }

    return updatedPartner;
  });

  const dispatch = useAppDispatch();

  const [partnerOptions, setPartnerOptions] = useState([
    { id: '', name: '- All Partner -', disabled: false },
    ...partnerList,
  ]);

  const onSubmit = async (value: any) => {
    try {
      const formData = {
        ...value,
        content: content,
        partner_ids: partnerIds.filter((id) => id !== ''),
        email_cc: value.email_cc
          ? value.email_cc.split(',').map((email: string) => email.trim())
          : [],
      };
      if (data?.id) {
        await dispatch(emailTemplate.updateData(data?.id, formData));
      } else {
        await dispatch(emailTemplate.createData(formData));
      }
      alertSuccess(`Email Template berhasil di ${data?.id ? 'update' : 'tambahkan'}`).then(() => {
        handleClose();
        dispatch(emailTemplate.fetchList(params));
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  const handleChangePartner = (event: SelectChangeEvent) => {
    const {
      target: { value },
    }: any = event;
    let newValue = value;
    const filteredPartnerOptions = partnerOptions.filter(
      (val: { id: string; disabled: boolean }) => !val.disabled,
    );

    if (value.includes('')) {
      if (partnerIds.includes('')) {
        newValue = value.filter((val: string) => val !== '');
      } else {
        newValue = filteredPartnerOptions.map((val: { id: string }) => val.id);
      }
    } else if (partnerIds.includes('') && !value.includes('')) {
      newValue = [];
    } else {
      newValue = value;
      if (filteredPartnerOptions.length - 1 === value.length) {
        newValue.unshift('');
      }
    }

    setPartnerIds(newValue);
    setValue('partner_ids', newValue);
  };

  const handleChangeCompany = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setValue('company_id', +value);
    dispatch(emailTemplate.getCompanyPartner(+value));
    setPartnerIds(['']);
    setValue('partner_ids', ['']);
  };

  const handleChangeContent = (content: string) => {
    setContent(content);
    setValue('content', content);
  };

  const renderSelect = (selected: string[], items: any[]) => {
    if (selected.length === 0) {
      return '';
    }

    if (selected.includes('')) {
      return items[0]?.name;
    }

    const selectedNames = selected.map((value) => {
      const item = items.find((item) => item.id === value);
      return item ? item.name : '';
    });

    return selectedNames.join(', ');
  };

  const selectedCompanyId = useWatch({
    control: methods.control,
    name: 'company_id',
  });

  const NoteItem = ({ parameter, description }: { parameter: string; description: string }) => (
    <li style={{ marginBottom: '4px' }}>
      <span
        style={{
          backgroundColor: '#E7EDF1',
          letterSpacing: '0.05em',
          color: '#293845',
          fontWeight: 300,
          padding: '2px',
        }}>
        {parameter}
      </span>
      = {description}
    </li>
  );

  const notes = [
    { parameter: '{{partner_name}}', description: 'nama partner' },
    { parameter: '{{partner_payment_channel}}', description: 'nama bank dari rekening partner' },
    { parameter: '{{partner_bank_account_number}}', description: 'nomor rekening partner' },
    {
      parameter: '{{product_name}}',
      description: 'nama produk (ex: [PL Apartment A, Token Apartment A)',
    },
    { parameter: '{{company_name}}', description: 'nama company' },
    { parameter: '{{transferred_date}}', description: 'tanggal disbursement' },
    { parameter: '{{total_disbursement}}', description: 'jumlah dana yang dikirim ke partner' },
  ];

  useEffect(() => {
    if (open === true) {
      dispatch(company.fetchAll());
    }
  }, [dispatch, open]);

  useEffect(() => {
    setPartnerOptions([{ id: '', name: '- All Partner -', disabled: false }, ...partnerList]);

    if (
      partnerList.length > 0 &&
      (partnerIds.length === 0 || (partnerIds.length === 1 && partnerIds[0] === ''))
    ) {
      const newPartnerIds = [
        '',
        ...partnerList
          .filter((val: { id: string; disabled: boolean }) => !val.disabled)
          .map((partner: { id: string }) => +partner.id),
      ];
      setPartnerIds(newPartnerIds);
      setValue('partner_ids', newPartnerIds);
    }
  }, [selectedPartner]);

  useEffect(() => {
    const fetchPartners = async () => {
      if (data?.id && open === true) {
        methods.reset({ ...data });
        await dispatch(emailTemplate.getCompanyPartner(data.company_id));
        const partnerIdsFromData = data?.partners
          ? data.partners.map((partner: { id: number }) => partner.id)
          : [''];
        if (partnerIdsFromData.length === partnerList.length) {
          partnerIdsFromData.unshift('');
        }
        setPartnerIds(partnerIdsFromData);
        setContent(data.content || '');
        setValue('email_cc', data.email_cc ? data.email_cc.join(', ') : '');
      } else {
        methods.reset({
          ...emailTemplateDefaultValues,
        });
        setContent('');
        setPartnerIds(['']);
      }
    };

    fetchPartners();
  }, [open, data]);

  useEffect(() => {}, [partnerIds]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">
            {data?.id ? 'Edit' : 'Add'} Email Template
          </DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <SelectField
                name="company_id"
                nullValue={0}
                label="Company"
                customOnChange={(e: SelectChangeEvent<string>) => {
                  handleChangeCompany(e);
                }}
                options={companyList.map((data: any) => ({ value: data.id, label: data.name }))}
              />
              <Box sx={{ mb: 2 }}>
                <Label htmlFor="partner_ids">Partner</Label>
                <Select
                  fullWidth
                  displayEmpty
                  size="small"
                  multiple
                  name="partner_ids"
                  value={partnerIds}
                  onChange={(val: any) => handleChangePartner(val)}
                  renderValue={(val) => (val.length > 0 ? renderSelect(val, partnerOptions) : '')}
                  disabled={!selectedCompanyId}
                  error={!!errors.partner_ids}>
                  {partnerOptions.map((item: any, index: number) => (
                    <MenuItem key={index} value={item.id} disabled={item.disabled}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {item.name}
                        </Grid>
                        <Grid item xs={6} justifyContent="end" textAlign="end">
                          {partnerIds.includes(item.id) && <CheckIcon fontSize="small" />}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
                {Array.isArray(errors.partner_ids)
                  ? errors.partner_ids.map((error, index) => (
                      <FormHelperText key={index} error>
                        {error.message}
                      </FormHelperText>
                    ))
                  : errors.partner_ids && (
                      <FormHelperText error>{errors.partner_ids.message}</FormHelperText>
                    )}
              </Box>
              <InputField
                name="email_cc"
                label="CC Email Receiver"
                maxLength={0}
                autocomplete="off"
                placeholder="Email 1, Email 2, Email 3 (separated by comma (,))"
              />
              <InputField name="subject" label="Subject" maxLength={0} autocomplete="off" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextEditor
                    placeholder={content || 'Enter email content here...'}
                    initialContent={content}
                    onContentChange={handleChangeContent}
                  />
                  {errors.content && (
                    <FormHelperText error>{errors.content.message}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </FormProvider>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>Notes Parameter:</p>
                <ol style={{ margin: 0 }}>
                  {notes.map((note, index) => (
                    <NoteItem
                      key={index}
                      parameter={note.parameter}
                      description={note.description}
                    />
                  ))}
                </ol>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default EmailTemplateFormDialog;
