import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Props {
  vertical: number | 'top' | 'center' | 'bottom';
  horizontal: number | 'center' | 'left' | 'right';
  children: React.ReactNode;
}

export default function BasicPopover(props: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: props.vertical,
          horizontal: props.horizontal,
        }}>
        {props.children}
      </Popover>
    </div>
  );
}
