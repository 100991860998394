import { FilterAlt, FilterAltOff } from '@mui/icons-material';
import { Button, Grid, MenuItem, Select, TextField } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { debounce } from 'lodash';
import cashOut from '../../redux/actions/cash-out';
import moment from 'moment';
import company from '../../redux/actions/company';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme } from '@mui/material';

const CashOutFilter = ({ history }: any) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.cashOut);
  const companyList = useAppSelector((state) => state.company.dataAll);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [companyId, setCompanyId] = useState(0);
  const [status, setStatus] = useState('');
  const [dateType, setDateType] = useState('created_date');

  useEffect(() => {
    dispatch(company.fetchAll());
    return () => dispatch(cashOut.resetParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      cashOut.fetchList({
        ...data.params,
        key: 'company_id',
        date_type: dateType,
        value: companyId !== 0 ? companyId : null,
        page: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const [dateValue, setDateValue] = useState<DateRange<Date>>([null, null]);
  const handleFilter = () => {
    if ((dateValue[0] && !dateValue[1]) || (!dateValue[0] && dateValue[1])) {
      return;
    }
    let from_date = dateValue[0] ? moment(dateValue[0]).format('YYYY-MM-DD') : '';
    let to_date = dateValue[1] ? moment(dateValue[1]).format('YYYY-MM-DD') : '';

    dispatch(cashOut.fetchList({ ...data.params, page: 1, from_date, to_date }));
  };

  const filterDate = debounce((newValue: [Date | null, Date | null]) => {
    setDateValue(newValue);
    if ((newValue[0] && !newValue[1]) || (!newValue[0] && newValue[1])) {
      return;
    }

    let from_date = newValue[0] ? moment(newValue[0]).format('YYYY-MM-DD') : '';
    let to_date = newValue[1] ? moment(newValue[1]).format('YYYY-MM-DD') : '';

    if (from_date && to_date) {
      dispatch(cashOut.fetchList({ ...data.params, page: 1, from_date, to_date }));
    }
  }, 300);

  const filterStatus = (status: string) => {
    setStatus(status);
    dispatch(cashOut.fetchList({ ...data.params, status, page: 1 }));
  };

  const filterDateType = (dateType: string) => {
    setDateType(dateType);
    dispatch(cashOut.fetchList({ ...data.params, date_type: dateType, page: 1 }));
  };

  const handleReset = () => {
    setCompanyId(0);
    setStatus('');
    setDateType('created_date');
    setDateValue([null, null]);
    dispatch(
      cashOut.fetchList({
        ...data.params,
        page: 1,
        to_date: null,
        from_date: null,
        status: '',
        value: null,
      }),
    );
  };

  return (
    <Box>
      <Grid container sx={{ mb: 1 }} spacing={1} justifyContent="space-between">
        <Grid item md={6}>
          <Select
            size="small"
            fullWidth
            value={companyId}
            onChange={(e) => setCompanyId(parseInt(e.target.value as string))}>
            <MenuItem value={0}>- All Company -</MenuItem>
            {companyList.map((item: any, idx: number) => (
              <MenuItem key={idx} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={6}>
          <Select
            size="small"
            fullWidth
            displayEmpty
            value={status}
            onChange={(e) => filterStatus(e.target.value)}>
            <MenuItem value="">- All Status -</MenuItem>
            <MenuItem value="ready">Ready</MenuItem>
            <MenuItem value="requested">Requested</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
            <MenuItem value="done">Done</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item md={9} sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <Select
                size="small"
                displayEmpty
                fullWidth
                value={dateType}
                onChange={(e) => filterDateType(e.target.value)}>
                <MenuItem value="created_date">Create Date</MenuItem>
                <MenuItem value="settlement_date">Settlement Date</MenuItem>
              </Select>
            </Grid>
            <Grid item md={8}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DateRangePicker
                  value={dateValue}
                  onChange={(newValue) => {
                    filterDate(newValue);
                  }}
                  onAccept={handleFilter}
                  disableFuture
                  inputFormat="DD/MM/YYYY"
                  renderInput={(startProps, endProps) => (
                    <Fragment>
                      <TextField {...startProps} label="" size="small" />
                      <Box sx={{ mx: 1 }}> to </Box>
                      <TextField {...endProps} sx={{ mr: 1 }} label="" size="small" />
                    </Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            startIcon={<FilterAlt />}
            onClick={handleFilter}
            sx={{ mr: 1 }}>
            {!isSmallScreen && 'Filter'}
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<FilterAltOff />}
            onClick={handleReset}>
            {!isSmallScreen && 'Reset'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CashOutFilter;
