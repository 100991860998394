import {
  AccountBalance,
  Business,
  CoPresent,
  Home as HomeIcon,
  HomeWork,
  ManageAccounts,
  Payment,
  SettingsInputAntenna,
  Storefront,
  SyncAlt,
  Settings,
} from '@mui/icons-material';
import Menu from '../interfaces/Menu';
import CompanyIndex from '../views/company/CompanyIndex';
import CompanyHistoryIndex from '../views/company/history/CompanyHistoryIndex';
import CashIn from '../views/cash-in/CashInIndex';
import CashOut from '../views/cash-out/CashOutIndex';
import PartnerIndex from '../views/partner/PartnerIndex';
import PartnerHistoryIndex from '../views/partner/history/PartnerHistoryIndex';
import ProductIndex from '../views/product/ProductIndex';
import ChannelIndex from '../views/channel/Channelndex';
import ProviderIndex from '../views/provider/ProviderIndex';
import CompanyProviderIndex from '../views/company-provider/CompanyProviderIndex';
import Home from '../views/home/HomeIndex';
import UserIndex from '../views/user-management/user/UserIndex';
import RoleIndex from '../views/user-management/role/RoleIndex';
import EmailTemplateIndex from '../views/configuration/email-template/EmailTemplateIndex';
import LogEmailIndex from '../views/configuration/log-email/LogEmailIndex';

const MenuList: Menu[] = [
  {
    name: 'Home',
    path: '/',
    views: Home,
    exact: true,
    showMenu: true,
    icon: HomeIcon,
  },
  {
    name: 'Company',
    code: 'company',
    path: '/company',
    views: CompanyIndex,
    exact: true,
    showMenu: true,
    icon: Business,
  },
  {
    name: 'Company History',
    code: 'company_history',
    path: '/company/:id/history',
    views: CompanyHistoryIndex,
    exact: true,
    showMenu: false,
    icon: Business,
  },
  {
    name: 'Product',
    code: 'product',
    path: '/product',
    views: ProductIndex,
    exact: true,
    showMenu: true,
    icon: Storefront,
  },
  {
    name: 'Channel',
    code: 'channel',
    path: '/channel',
    views: ChannelIndex,
    exact: true,
    showMenu: true,
    icon: AccountBalance,
  },
  {
    name: 'Provider Channel',
    code: 'provider_channel',
    path: '/provider',
    views: ProviderIndex,
    exact: true,
    showMenu: true,
    icon: SettingsInputAntenna,
  },
  {
    name: 'Company Provider',
    code: 'company_provider',
    path: '/company-provider',
    views: CompanyProviderIndex,
    exact: true,
    showMenu: true,
    icon: HomeWork,
  },
  {
    name: 'Partner',
    code: 'partner',
    path: '/partner',
    views: PartnerIndex,
    exact: true,
    showMenu: true,
    icon: CoPresent,
  },
  {
    name: 'Partner History',
    code: 'partner_history',
    path: '/partner/:id/history',
    views: PartnerHistoryIndex,
    exact: true,
    showMenu: false,
    icon: CoPresent,
  },
  {
    name: 'Transaction',
    code: 'transaction',
    path: '/transaction',
    exact: true,
    showMenu: true,
    icon: SyncAlt,
    subMenu: [
      {
        name: 'Cash In',
        code: 'cash_in',
        path: '/cash-in',
        views: CashIn,
        exact: true,
        showMenu: true,
        icon: Payment,
      },
      {
        name: 'Cash Out',
        code: 'cash_out',
        path: '/cash-out',
        views: CashOut,
        exact: true,
        showMenu: true,
        icon: Payment,
      },
    ],
  },
  {
    name: 'User Management',
    code: 'user_management',
    path: '/user-management',
    exact: true,
    showMenu: true,
    icon: ManageAccounts,
    subMenu: [
      {
        name: 'User',
        code: 'user',
        path: '/user',
        views: UserIndex,
        exact: true,
        showMenu: true,
        icon: Payment,
      },
      {
        name: 'Role',
        code: 'role',
        path: '/role',
        views: RoleIndex,
        exact: true,
        showMenu: true,
        icon: Payment,
      },
    ],
  },
  {
    name: 'Configuration',
    code: 'configuration',
    path: '/user-management',
    exact: true,
    showMenu: true,
    icon: Settings,
    subMenu: [
      {
        name: 'Email Template',
        code: 'email_template',
        path: '/email-template',
        views: EmailTemplateIndex,
        exact: true,
        showMenu: true,
        icon: Payment,
      },
      {
        name: 'Log Email',
        code: 'email_log',
        path: '/log-email',
        views: LogEmailIndex,
        exact: true,
        showMenu: true,
        icon: Payment,
      },
    ],
  },
];

export default MenuList;
