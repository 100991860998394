import { FilterAlt, FilterAltOff, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { debounce } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import logEmail from '../../../redux/actions/log-email';
import company from '../../../redux/actions/company';
import partner from '../../../redux/actions/partner';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';

const LogEmailFilter = () => {
  const [companyId, setCompanyId] = useState(0);
  const [partnerId, setPartnerId] = useState(0);
  const [status, setStatus] = useState('');
  const [dateValue, setDateValue] = useState<DateRange<Date>>([null, null]);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.logEmail);
  const companyList = useAppSelector((state) => state.company.dataAll);
  const partnerList = useAppSelector((state) => state.partner.dataList);

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleSearch = debounce((e) => {
    dispatch(logEmail.fetchList({ ...data.params, page: 1, q: e.target.value }));
  }, 500);

  const handleFilter = () => {
    if ((dateValue[0] && !dateValue[1]) || (!dateValue[0] && dateValue[1])) {
      return;
    }
    let start_date = dateValue[0] ? moment(dateValue[0]).format('YYYY-MM-DD') : '';
    let end_date = dateValue[1] ? moment(dateValue[1]).format('YYYY-MM-DD') : '';
    dispatch(logEmail.fetchList({ ...data.params, page: 1, start_date, end_date }));
  };

  const filterDate = debounce((newValue: [Date | null, Date | null]) => {
    setDateValue(newValue);
    if ((newValue[0] && !newValue[1]) || (!newValue[0] && newValue[1])) {
      return;
    }

    let start_date = newValue[0] ? moment(newValue[0]).format('YYYY-MM-DD') : '';
    let end_date = newValue[1] ? moment(newValue[1]).format('YYYY-MM-DD') : '';

    if (start_date && end_date) {
      dispatch(logEmail.fetchList({ ...data.params, page: 1, start_date, end_date }));
    }
  }, 300);

  const filterStatus = (status: string) => {
    setStatus(status);
    dispatch(logEmail.fetchList({ ...data.params, status, page: 1 }));
  };

  const filterCompany = (id: number) => {
    setCompanyId(id);
    console.log(data);
    dispatch(
      logEmail.fetchList({
        ...data.params,
        company: id !== 0 ? id : null,
      }),
    );
  };

  const filterPartner = (id: number) => {
    setPartnerId(id);
    dispatch(
      logEmail.fetchList({
        ...data.params,
        partner: id !== 0 ? id : null,
        page: 1,
      }),
    );
  };

  const handleReset = () => {
    setCompanyId(0);
    setPartnerId(0);
    setStatus('');
    setDateValue([null, null]);
    dispatch(
      logEmail.fetchList({
        ...data.params,
        page: 1,
        start_date: '',
        end_date: '',
        status: '',
        partner: 0,
        company: 0,
      }),
    );
  };

  useEffect(() => {
    dispatch(company.fetchAll());
    // dispatch(partner.fetchList({ page: 1, limit: 1000 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyId === 0) {
      setPartnerId(0);
      dispatch(partner.fetchList({ page: 1, limit: 1000 }));
    } else if (companyId !== 0) {
      dispatch(partner.fetchList({ page: 1, limit: 1000, key: 'company_id', value: companyId }));
    }
  }, [companyId]);

  return (
    <Box>
      <Grid container sx={{ mb: 1 }} spacing={1}>
        <Grid item md={6}>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <Select
                size="small"
                fullWidth
                value={companyId}
                onChange={(e) => filterCompany(parseInt(e.target.value as string))}>
                <MenuItem value={0}>- All Company -</MenuItem>
                {companyList.map((item: any, idx: number) => (
                  <MenuItem key={idx} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={4}>
              <Select
                size="small"
                fullWidth
                value={partnerId}
                onChange={(e) => filterPartner(parseInt(e.target.value as string))}>
                <MenuItem value={0}>- All Partner -</MenuItem>
                {partnerList.map((item: any, idx: number) => (
                  <MenuItem key={idx} value={item.id}>
                    {item.partner}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={4}>
              <Select
                size="small"
                fullWidth
                displayEmpty
                value={status}
                onChange={(e) => filterStatus(e.target.value)}>
                <MenuItem value="">- All Status -</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="sent">Sent</MenuItem>
                <MenuItem value="opened">Opened</MenuItem>
                <MenuItem value="failed">Failed</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DateRangePicker
                value={dateValue}
                onChange={(newValue) => {
                  filterDate(newValue);
                }}
                onAccept={handleFilter}
                disableFuture
                inputFormat="DD/MM/YYYY"
                renderInput={(startProps, endProps) => (
                  <Fragment>
                    <TextField {...startProps} label="" size="small" />
                    <Box sx={{ mx: 1 }}> to </Box>
                    <TextField {...endProps} label="" size="small" />
                  </Fragment>
                )}
              />
            </LocalizationProvider>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 2, gap: 1 }}>
              <Box>
                <Button variant="contained" startIcon={<FilterAlt />} onClick={handleFilter}>
                  {!isSmallScreen && 'Filter'}
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<FilterAltOff />}
                  onClick={handleReset}>
                  {!isSmallScreen && 'Reset'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 1 }} justifyContent="flex-end">
        <Grid item xs={12} md={6}>
          <OutlinedInput
            fullWidth
            onChange={handleSearch}
            placeholder="Search"
            endAdornment={<Search color="disabled" />}
            size="small"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogEmailFilter;
